import MomentUtils from '@date-io/moment';
import {
  jssPreset,
  makeStyles,
  StylesProvider,
  ThemeProvider
} from '@material-ui/core';
import Fade from '@material-ui/core/Fade';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { create } from 'jss';
import rtl from 'jss-rtl';
import { SnackbarProvider } from 'notistack';
import type { FC } from 'react';
import React, { useEffect } from 'react';
import { MemoryRouter, BrowserRouter } from 'react-router-dom';
import GlobalStyles from 'src/components/GlobalStyles';
import GoogleAnalytics from 'src/components/GoogleAnalytics';
import ScrollReset from 'src/components/ScrollReset';
import { AuthProvider } from 'src/contexts/JWTAuthContext';
import { SocketProvider } from 'src/contexts/SocketContext';
import useSettings from 'src/hooks/useSettings';
import type { Theme } from 'src/theme';
import { createTheme } from 'src/theme';
import SleepHandler from './components/SleepHandler';
import UpdateHandler from './updateHandler';
import { appInsights } from 'src/utils/appInsight';
import CheckoutRoutes from 'src/routes';

const jss = create({ plugins: [...jssPreset().plugins, rtl()] });
// const history = createBrowserHistory();

const useStyles = makeStyles((theme: Theme) => ({

  snack: {
    '& .MuiSnackbarContent-root': {
      padding: 12,
      margin: 10,
      width: '100%',
      borderRadius: '5px'
    },
    '& .MuiSnackbarContent-message': {
      fontSize: '3rem'
    },
    '& .MuiSvgIcon-root': {
      fontSize: '3rem!important'
    },
    '& .MuiButtonBase-root': {
      padding: '0.6rem 1rem',
      fontSize: '2rem',
      marginRight: '1rem'
    }
  },
  snackContainerRoot: {
    width: '100% !important',
  },
}));

const App: FC = () => {
  useEffect(()=> {
    appInsights.trackPageView();
  }, [])
  const { settings } = useSettings();
  const classes = useStyles();
  const theme = createTheme({
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    theme: settings.theme
  });
  let Router = MemoryRouter;
  if (process.env.REACT_APP_TYPE === 'BROWSER') Router = BrowserRouter;
  return (
    <ThemeProvider theme={theme}>
      <StylesProvider jss={jss}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <SnackbarProvider
            dense
            maxSnack={1}
            classes={{
              root: classes.snack,
              containerRoot: classes.snackContainerRoot,
            }}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            TransitionComponent={Fade}
          >
            <UpdateHandler>
              <Router>
                <AuthProvider>
                  <SocketProvider>
                    <SleepHandler />
                    <GlobalStyles />
                    <ScrollReset />
                    <GoogleAnalytics />
                     <CheckoutRoutes/>
                  </SocketProvider>
                </AuthProvider>
              </Router>
            </UpdateHandler>
          </SnackbarProvider>
        </MuiPickersUtilsProvider>
      </StylesProvider>
    </ThemeProvider >
  );
};

export default App;
